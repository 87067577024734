<template>
  <a-card>
    <a-page-header :title="'账套列表'" />
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row>
        <a-col :span="6">
          <a-form-model-item label="账套ID">
            <a-input v-model="form.adPlatformAccountId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="账套名称">
            <a-input v-model="form.adPlatformAccountName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="账套标签">
            <a-select v-model="form.tagIdList" mode="multiple" placeholder="请选择" allow-clear>
              <a-select-option v-for="item in accountHjkTagList" :key="item.id" :value="item.id">
                {{ item.tagName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="授权状态">
            <a-select v-model="form.adPlatformAccountAuthStatus" placeholder="请选择" allow-clear>
              <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="flex-end">
          <a-space>
            <base-button
              :type="'primary'"
              :title="'导出'"
              @onClickBtn="exportExcel"
              :loading="exportLoading"
            ></base-button>
            <base-button :title="'重置'" @onClickBtn="handleReset"></base-button>
            <base-button :type="'primary'" :title="'查询'" @onClickBtn="handleSearch"></base-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => index"
      :pagination="false"
    >
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
import accountAuthRecordApi from '@/api/accountAuthRecord';
import accountHjkTagApi from '@/api/accountHjkTag';
import { downloadExcel } from '@/utils';

export default {
  data() {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      form: {
        adPlatformAccountId: undefined,
        adPlatformAccountName: undefined,
        tagIdList: undefined,
        adPlatformAccountAuthStatus: undefined,
      },
      exportLoading: false,
      accountHjkTagList: [],
      statusList: [
        { name: '授权中', value: 1 },
        { name: '已失效', value: 2 },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      dataList: [],
      columns: [
        {
          align: 'left',
          title: '账套ID',
          dataIndex: 'adPlatformAccountId',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '账套名称',
          dataIndex: 'adPlatformAccountName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '巨量引擎用户ID',
          dataIndex: 'oceanOrgId',

          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '巨量引擎用户名',
          dataIndex: 'oceanOrgName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '授权状态',
          dataIndex: 'adPlatformAccountAuthStatus',
          customRender: (text, row) => {
            return text == 1 ? '授权中' : '已失效';
          },
        },
        {
          align: 'left',
          title: '关联广告主',
          dataIndex: 'advertiserName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'left',
          title: '标签',
          dataIndex: 'tagList',

          customRender(text) {
            return text.map((item) => item.tagName).join(',') || '-';
          },
        },
      ],
    };
  },
  computed: {
    params() {
      return {
        ...this.form,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
    },
  },
  created() {
    this.getDataList();
    this.getAccountHjkTagList();
  },
  methods: {
    getAccountHjkTagList() {
      accountHjkTagApi
        .getHjkTagListApi({ page: 1, size: 9999 })
        .then((res) => {
          if (res.code == 200) {
            this.accountHjkTagList = res.data.list || [];
          } else {
            this.accountHjkTagList = [];
            console.error(`获取列表失败${res}`);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handleReset() {
      this.form = {
        adPlatformAccountId: undefined,
        adPlatformAccountName: undefined,
        tagIdList: undefined,
        adPlatformAccountAuthStatus: undefined,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      accountAuthRecordApi.getAccountAuthRecordListApi(this.params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取列表失败${res}`);
        }
      });
    },
    async exportExcel() {
      this.exportLoading = true;
      const res = await accountAuthRecordApi
        .exportAccountAuthRecordApi(this.params)
        .finally(() => (this.exportLoading = false));
      downloadExcel(res, '账套列表');
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.txt {
  padding: 0 15px;
}
.name {
  padding: 0 15px;
  white-space: nowrap;
}
</style>
